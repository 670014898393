<template>
  <div class="login">
    <div class="centered-container">
      <md-content class="md-elevation-3">

        <div class="title">
          <img src="@/assets/images/logo-altice-empresas-h-b.png">
          <div class="md-title">Public Information</div>
          <div class="md-body-1">Desconectado, breve será redirecionado para página de login.</div>
        </div>

        <div class="loading-overlay" v-if="loading">
          <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
        </div>

      </md-content>
    </div>
  </div>
</template>

<script>
import * as Constants from '@/store/constants'

export default {
  name: 'logout',
  data () {
    return {
      loading: false
    }
  },
  methods: {
    redirect () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        window.location = Constants.MEOID_LOGOUT
        this.$router.push('/')
      }, 1000)
    }
  },
  created () {
    setTimeout(() => {
      this.redirect()
    }, 2000)
  }
}
</script>

<style lang="scss">
.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 30px;
    img {
      margin-bottom: 16px;
      max-width: 80px;
    }
  }
  .actions {
    .md-button {
      margin: 0;
    }
  }
  .form {
    margin-bottom: 60px;
  }
  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    position: relative;
  }
  .loading-overlay {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
